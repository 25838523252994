<script>
import Layout from "../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";
import ChartBonus from "@/components/widgets/chart-bonus";
import ChartPoints from "@/components/widgets/chart-points";
import firebase from "firebase/app";
import "firebase/messaging";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
      'We sent a confirmation email, access your email!': 'Enviamos um email de confirmação, acesse seu email!',
      'Please validate your registration email!': 'Por favor, valide seu email de cadastro!',
      'Send email': 'Enviar email',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
      'We sent a confirmation email, access your email!': 'Le enviamos un correo electrónico de confirmación, acceda a su correo electrónico!',
      'Please validate your registration email!': '¡Valide su correo electrónico de registro!',
      'Send email': 'Enviar correo electrónico',
    }
  },
  components: { Layout, Banners, Stat, ChartBonus, ChartPoints },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      graduation: {
        name: "-",
        points: "-.--",
      },

      statData: null,
      wallets: null,

      notifications: true,

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null
        },
        subtotal: 'R$ 0,00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: 'R$ 0,00',

      },

      emailValidation: false
    };
  },
  methods: {
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.bonus = response.data.total
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = response.data.balance
            this.getStatData();
          }
        })
    },
    getGraduation()
    {
      api
        .get('report/graduation')
        .then(response => {
          if (response.data.status=='success') {
            this.graduation.name = response.data.graduation.name
            this.graduation.points = response.data.graduation.points
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          icon: "package",
          title: "Package",
          value: this.account.user.package,
        },
        {
          icon: "graduation",
          title: "Graduation",
          value: this.graduation.name,
        },
        {
          icon: "balance",
          title: "Current Balance",
          value: this.total.balance,
        },
        {
          icon: "bonus",
          title: "Total Bonus",
          value: this.total.bonus
        }
      ]
    },
    checkNotifications() {
      if (firebase.messaging.isSupported()) {
        api
          .get('user/notifications')
          .then(response => {
            if (response.data.status==='success') {
              if (response.data.devices == 'active') {
                this.notifications = true
              } else {
                this.notifications = false
              }
            } else {
              this.notifications = false
            }
          });
      }
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get('store/cart')
          .then(response => {
            if (response.data.status=='success') {
              this.setStorage(response.data)
            }
          })
          .catch(error => {
            this.cart.errored = error
          })
      } else {
        this.cart = JSON.parse(localStorage.cart)
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total
      this.cart.items.list = data.items.list
      this.cart.subtotal = data.subtotal
      this.cart.shipping = data.shipping
      this.cart.total = data.total

      localStorage.cart = JSON.stringify(this.cart)
    },
    sendEmail() {
      api
        .get('user/email/validation')
        .then(response => {
          if (response.data.status == 'success') {
            this.$store.state.account.user.email.status = 'waiting'
            this.emailValidation = true;
          }
        })
    }
  },
  mounted() {
    this.getGraduation()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.checkNotifications()
    this.getCart()
    this.$store.state.account.user.email.status = 'waiting';
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="!notifications" to="/account/notifications" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-info rounded">
          <div class="d-flex align-items-center">
            <i class="bx bx-bell font-size-24 align-middle mr-3"></i>
            <div>Fique informado de todas as atualizações do seu escritório virtual, ative suas notificações Web Push!</div>
          </div>
        </div>
      </div>
    </router-link>

    <router-link v-if="cart.items.total > 0" to="/store/cart" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-warning rounded p-0">
          <div class="d-flex align-items-center">
            <img class="rounded-circle header-profile-user m-2 mx-2" :src="cart.items.list[Object.keys(cart.items.list)[0]].image" width="30px" height="30px">
            <div class="py-3">Você deixou alguns produtos em seu carrinho, clique aqui para finalizar o seu pedido!</div>
          </div>
        </div>
      </div>
    </router-link>

    <div v-if="account.user.email.status == 'pending'" :class="(!emailValidation) ? 'alert alert-info' : 'alert alert-success' ">
      <p v-if="!emailValidation">
        {{ t('Please validate your registration email!') }}
        <button @click="sendEmail" class="btn btn-primary ml-3">{{ t('Send email') }}</button>
      </p>
      <p v-else>
        {{ t('We sent a confirmation email, access your email!') }}
      </p>
    </div>

    <Banners></Banners>

    <div class="d-block d-sm-none">
      <div class="row text-center mb-3">
        <div class="col-3">
          <router-link :to="'/register/'+account.user.username" class="text-dark">
            <div class="bg-dark rounded-circle mb-2 p-3 d-inline-block"><i class="bx bxs-user-plus align-middle text-white h1 mb-0"></i></div>
            <div class="d-block font-size-11">{{ t('Register') }}</div>
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/wallet/extract" class="text-dark">
            <div class="bg-dark rounded-circle mb-2 p-3 d-inline-block"><i class="bx bx-list-check align-middle text-white h1 mb-0"></i></div>
            <div class="d-block font-size-11">{{ t('Extract') }}</div>
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/wallet/withdrawal" class="text-dark">
            <div class="bg-dark rounded-circle mb-2 p-3 d-inline-block"><i class="bx bx-wallet-alt align-middle text-white h1 mb-0"></i></div>
            <div class="d-block font-size-11">{{ t('Withdrawal') }}</div>
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/reports/graduation" class="text-dark">
            <div class="bg-dark rounded-circle mb-2 p-3 d-inline-block"><i class="bx bx-crown align-middle text-white h1 mb-0"></i></div>
            <div class="d-block font-size-11">{{ t('Graduation') }}</div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-6 col-xl-3">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark">
              <i class="bx bx-line-chart mr-1"></i> {{ t('Total Bonus') }}
            </h5>
            <ChartBonus></ChartBonus>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark">
              <i class="bx bx-line-chart mr-1"></i> {{ t('Total Points') }}
            </h5>
            <ChartPoints></ChartPoints>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t('Referral link') }}
            </h5>
            <p class="m-0 notranslate">
              https://app.anrfranquia.com.br/{{ account.user.username }}
              <button class="btn btn-link p-0 ml-2" v-clipboard:copy="'https://app.anrfranquia.com.br/' + account.user.username"><i class="bx bx-copy font-size-18 text-danger"></i></button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> Link de divulgação
            </h5>
            <p class="m-0 notranslate">
              https://oportunidade.anrfranquia.com.br/{{ account.user.username }}
              <button class="btn btn-link p-0 ml-2" v-clipboard:copy="'https://oportunidade.anrfranquia.com.br/' + account.user.username"><i class="bx bx-copy font-size-18 text-danger"></i></button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
